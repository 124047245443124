.sidebarOption {
    display: flex;
    align-items: center;
    color: grey;
    height: 40px;
    cursor: pointer;
    transition: 200ms color ease-in;
}

.sidebar > hr {
    border: 1px solid #282828;
    width: 90%;
    margin: 10px auto;
}

.sidebarOption:hover {
    color: white;
}

.sidebarOption__icon {
    padding: 0 10px 0 10px;;
}

.sidebarOption > p {
    margin-top: 10px; 
    margin-left: 20px;
    font-size: 14px;
}