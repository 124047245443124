.songRow {
  margin-left: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  z-index: 100;
  color: white;
}

.songRow:hover {
    cursor: pointer;
    background-color: black;
    opacity: 0.8;
}

.songRow__info {
    margin-left: 20px;
}
  
.songRow__info > h1 {
    font-size: 16px;
}
  
.songRow__info > p {
    font-size: 14px;
    margin-top: 3px;
    color: gray;
}
  
.songRow__album {
    height: 40px;
    width: 40px;
}