.body {
    padding: 30px;
    flex: 0.8;
    height: 100vh;
    color: white;
    width: 100%;
    overflow-y: overlay;
    /* background-color: rgb(91, 87, 115); */
    background: linear-gradient(rgb(91, 87, 115), rgba(0, 0, 0, 1));
}

.body::-webkit-scrollbar {
    display: none;
}

.body__info > img {
    height: 20vw;
    margin: 0 20px;
    box-shadow: 0 4px 60px rgba(0, 0, 0, 0.5);
}

.body__info {
    display: flex;
    align-items: flex-end;
    padding: 10px;
}

.body__infoText {
    flex: 1;
}

.body__infoText > h2 {
    font-size: 48px;
    margin-bottom: 10px;
}

.body__infoText > p {
    font-size: 14px;
}

.body__shuffle {
  font-size: 80px !important;
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.body__shuffle:hover {
    transition: 100ms traform ease-in;
    transform: scale(1.08);
  }

.body__icons {
  display: flex;
  align-items: center;
}

.body__icons > .MuiSvgIcon-root {
  margin-right: 20px;
}

.body__shuffle:hover {
  transition: 100ms transform ease-in;
  transform: scale(1.08);
}

.body__songs {
  z-index: 1;
  margin: 20px -30px;
}
