.sidebar {
    height: 100vh;
    flex: 0.2;
    color: white;
    min-width: 230px;
    height: 100vh;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #040404;
}

.sidebar__logo {
    object-fit: contain;
    height: 70px;
    padding: 10px;
    margin-right: auto;
}

.sidebar__title {
    margin-left: 10px;
    padding: 5px;
    font-size: 12px;
}

.sidebarOption > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}